import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth/authGuard";
import { authGuardSuper } from "@auth/authGuardSuper";
import { MetricSender } from "@helpers/MetricSender";

import AuthLanding from "@views/AuthLanding.vue";
import AuthCallback from "@views/AuthCallback.vue";
import HomeV4 from "@views/HomeV4.vue";
import Search from "@views/Search.vue";
import EditProfileRedirect from "@views/EditProfileRedirect.vue";
import CustomerAdminProfile from "@views/Community/AdminProfile/index.vue";
import ItineraryPreview from "@views/ItineraryPreview.vue";
// import PrintAdventure from "@views/PrintAdventure.vue"
import CmsEditorV2 from "@views/CMS/CmsEditorV2.vue";
import MyItineraries from "@views/MyItineraries.vue";
import Contact from "@views/static/Contact.vue";
import Terms from "@views/static/Terms.vue";
import Infringement from "@views/static/Infringement.vue";
import Privacy from "@views/static/Privacy.vue";
import Careers from "@views/static/Careers.vue";
import Pages from "@views/Pages.vue";
import ItinereraryStepsMap from "@views/ItineraryStops/StopsMap.vue";
import CommunitiesMap from "@views/Community/CommunitiesMap.vue";
import CommunityPageAdmin from "@views/Community/CommunityPageAdmin.vue";
import SubscriptionSettings from "@views/Community/SubscriptionSettings.vue";
import UserAdmin from "@views/Community/UserAdmin/index.vue";
import Redirect from "@views/Redirect.vue";
import PaidCollabCommunityAdmin from "@views/PaidCollab/AllCollabs.vue";
import CommunityAdminDashboard from "@views/Community/Dashboard/index.vue";
import WidgetDirections from "@views/Integration/WidgetDirections.vue";
import AdventureWidgets from "@views/Integration/AdventureWidgets.vue";
import CustomIntegration from "@views/Integration/CustomIntegration.vue";
import CreatorDashboard from "@views/Creator/CreatorDashboard/index.vue";
import CreatorSettings from "@views/Creator/CreatorSettings/index.vue";
import CollabOpportunity from "@views/Creator/CollabOpportunity/CollabOpportunityV2/index.vue";
import CommunityPhotosV2 from "@views/Community/CommunityPhotosV2.vue";
import CommunityPhotosV3 from "@views/Community/CommunityPhotosV3.vue";
import PhotoWallAdminOld from "@views/Integration/PhotoWallAdmin.vue";
import PhotoWallAdminV2 from "@views/Integration/PhotoWallAdminV2/index.vue";
import PhotoGalleryAdmin from "@views/Community/PhotoGalleryAdmin.vue";
import PrintMedia from "@views/Community/PrintMedia.vue";
import AdventureSummary from "@views/Integration/AdventureSummary/index.vue";
import CommunityAdventureApproval from "@views/Community/CommunityAdventureApproval.vue";
import CommunityAdventureMetrics from "@views/Community/CommunityAdventureMetrics.vue";
import CommunityStatsSummary from "@views/Community/CommunityStatsSummary.vue";
import UserUploadCampaigns from "@views/Community/UserUploadCampaigns/index.vue";
import NonUserPhotoUpload from "@views/Community/NonUserPhotoUpload.vue";
import UserUploadedGalleryAdmin from "@views/Community/UserUploadedGalleryAdmin.vue";
import ShareStopSummary from "@views/Community/ShareStopSummary.vue";
import ShareStopSummaryV2 from "@views/Community/ShareStopSummaryV2/index.vue";
import SavedPosts from "@views/Community/ShareStopSummaryV2/SavedPosts/index.vue";
import CollabSummaryPublic from "@views/CollabSummaryPublic.vue";
import RecentCreatorVisits from "@views/RecentCreatorVisits.vue";
import CreatorProfilePublic from "@views/CreatorProfilePublic/index.vue";
import IndustryInsights from "@views/IndustryInsights.vue";
import SpotlightOrgSetup from "@views/SpotlightOrgSetup.vue";
// import QuickImageUpload from "@views/CMS/QuickImageUpload.vue";
import NotFound from "@views/NotFound.vue";
import RedirectingElsewhere from "@views/RedirectingElsewhere.vue";
import NewCollabs from "@views/NewCollabs.vue";
import NotificationSettingsRedirect from "@views/NotificationSettingsRedirect.vue";
import FeatureFlags from "@logic/FeatureFlags";
import AdTracking from "@views/AdTracking.vue";
import CreatorReferrals from "@views/Creator/Referrals/index.vue";
import VisitInspiration from "@views/VisitInspiration/index.vue";
import OnboardingAdventure from "@views/Creator/CreatorOnboardingV2/OnboardingAdventure/index.vue";

const routes: RouteRecordRaw[] = [
  {
    // NOTE: This is also set as the callback in authWrapper (so keep the path in sync)
    path: "/finish-login",
    name: "AuthCallback",
    component: AuthCallback,
  },
  {
    // User lands here if they go straight to an authenticated page (without being logged in)
    path: "/authenticate",
    name: "AuthLanding",
    component: AuthLanding,
  },
  {
    path: "/",
    alias: ["/home", "/about", "/join"], // Can probably retire the old /about and /join static routes soon
    name: "Home",
    component: HomeV4,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    // This should be deprecated, making it authenticated for now before we remove it
    beforeEnter: authGuard,
  },
  {
    path: "/itinerary/:itineraryId",
    name: "ItineraryPreview",
    component: ItineraryPreview,
  },
  {
    // New creator page with inline editing
    path: "/creator/:creatorId",
    alias: "/sherpa/:creatorId", // old route for back compat
    name: "CreatorProfilePublic",
    component: CreatorProfilePublic,
  },
  {
    // Redirect for the old edit profile route
    // When we added inline editing for creators, we split off the customer admin profile
    path: "/profile/:authNameId?", // Can be an authNameId or creatorId
    name: "EditProfileRedirect",
    component: EditProfileRedirect,
    // Force the route to be authenticated
    beforeEnter: authGuard,
  },
  {
    // Customer admins have a separate, simplified profile now
    path: "/adminprofile/:creatorId",
    name: "CustomerAdminProfile",
    component: CustomerAdminProfile,
    // Force the route to be authenticated
    beforeEnter: authGuard,
  },
  {
    path: "/stops/:itineraryId",
    name: "ItineraryStepsV2",
    alias: "/steps/:itineraryId",
    component: ItinereraryStepsMap,
  },
  {
    path: "/creator/:creatorId/onboarding-adventure",
    name: "OnboardingAdventure",
    component: OnboardingAdventure,
    beforeEnter: authGuard,
  },
  {
    path: "/creator/:creatorId/settings",
    name: "CreatorSettings",
    component: CreatorSettings,
    // Force the route to be authenticated
    beforeEnter: authGuard,
  },
  {
    path: "/creator/:creatorId/referrals",
    name: "CreatorReferrals",
    component: CreatorReferrals,
    // Force the route to be authenticated
    beforeEnter: authGuard,
  },
  {
    path: "/content/editor",
    alias: "/content/editorV2",
    name: "CmsEditorV2",
    component: CmsEditorV2,
    // Force the route to be authenticated
    beforeEnter: authGuard,
  },
  {
    path: "/content/mine",
    name: "MyItineraries",
    component: MyItineraries,
    // Force the route to be authenticated
    beforeEnter: authGuard,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/home/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/home/infringement",
    name: "Infringement",
    component: Infringement,
  },
  {
    path: "/home/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/careers",
    name: "Careers",
    component: Careers,
  },
  {
    path: "/destination/:pageId",
    alias: ["/community/:pageId", "/org/:pageId"],
    name: "Pages",
    component: Pages,
  },
  {
    path: "/admin/printmedia/:pageId",
    name: "PrintMedia",
    component: PrintMedia,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/insights/:pageId",
    name: "IndustryInsights",
    component: IndustryInsights,
    // Force the route to be authenticated
    beforeEnter: authGuard,
  },
  {
    path: "/admin/inspiration/:pageId",
    name: "VisitInspiration",
    component: VisitInspiration,
    // Force the route to be authenticated
    beforeEnter: authGuard,
  },
  {
    path: "/settings/destinationpage/:pageId",
    alias: ["/admin/communitypage/:pageId", "/settings/communitypage/:pageId"], // Old path
    name: "CommunityPageAdmin",
    component: CommunityPageAdmin,
    beforeEnter: authGuard,
  },
  {
    path: "/settings/subscription/:pageId",
    name: "SubscriptionSettings",
    component: SubscriptionSettings,
    beforeEnter: authGuard,
  },
  {
    path: "/settings/useradmin/:pageId",
    name: "UserAdmin",
    component: UserAdmin,
    beforeEnter: authGuard,
  },
  {
    path: "/redirect",
    name: "Redirect",
    component: Redirect,
  },
  {
    path: "/new-collabs",
    name: "NewCollabsRedirect",
    component: NewCollabs,
    beforeEnter: authGuard,
  },
  {
    path: "/notification-settings",
    name: "NotificationSettingsRedirect",
    component: NotificationSettingsRedirect,
    beforeEnter: authGuard,
  },
  {
    path: "/collabs/:communityId/:collabInputId",
    name: "CollabSummaryPublic",
    component: CollabSummaryPublic,
  },
  {
    path: "/creatorvisits",
    name: "RecentCreatorVisits",
    component: RecentCreatorVisits,
  },
  {
    path: "/learn",
    name: "Learn",
    component: AdTracking,
  },
  {
    path: "/super",
    // Only supers can access anything under /super
    beforeEnter: [authGuard, authGuardSuper],
    children: [
      {
        path: "all-photos",
        name: "AllPhotos",
        component: () => import("/src/views/admin/AllPhotos.vue"),
      },
      {
        path: "feature-usage",
        name: "CustomerFeatureSummary",
        component: () => import("/src/views/admin/CustomerFeatureSummary.vue"),
      },
      {
        path: "",
        name: "AdminIndex",
        component: () => import("/src/views/admin/AdminIndex.vue"),
      },
      {
        path: "profiles",
        name: "AllProfiles",
        component: () => import("/src/views/admin/AllProfiles.vue"),
      },
      {
        path: "messages",
        name: "LatestMessages",
        component: () => import("/src/views/admin/LatestMessages.vue"),
      },
      {
        path: "config",
        name: "Config",
        component: () => import("/src/views/admin/Config.vue"),
      },
      {
        path: "configadmin",
        name: "ConfigAdmin",
        component: () => import("/src/views/admin/ConfigAdmin.vue"),
      },
      {
        path: "collabs/creatorcreationrates",
        name: "CreatorCretionRates",
        component: () => import("/src/views/admin/CreatorCreationRates.vue"),
      },
      {
        path: "collabs/creatorsummary",
        name: "SuperUserCollabCreatorSummary",
        component: () => import("/src/views/PaidCollab/SuperUserCollabCreatorSummary.vue"),
      },
      {
        path: "collabs/all",
        name: "SuperUserAllCollabs",
        component: () => import("/src/views/PaidCollab/SuperUserAllCollabs.vue"),
      },
      {
        path: "collabs/:communityId/:collabInputId",
        name: "SuperUserCollabDetails",
        component: () => import("/src/views/PaidCollab/SuperUserCollabDetails.vue"),
      },
      {
        path: "customers/new",
        name: "CreateCustomer",
        component: () => import("/src/views/admin/CreateCustomer.vue"),
      },
      {
        path: "customers/insights",
        name: "CustomerInsights",
        component: () => import("/src/views/admin/CustomerInsights.vue"),
      },
      {
        path: "customersuccess",
        alias: "customers/success",
        name: "CustomerSuccess",
        component: () => import("/src/views/admin/CustomerSuccess.vue"),
      },
      {
        path: "creatorsuccess",
        name: "CreatorSuccess",
        component: () => import("/src/views/admin/CreatorSuccess.vue"),
      },
      {
        path: "creators/:id",
        name: "EditCreator",
        component: () => import("/src/views/admin/EditCreator.vue"),
      },
      {
        path: "customers/usage",
        name: "CustomerUsage",
        component: () => import("/src/views/admin/CustomerUsage.vue"),
      },
      {
        path: "engagement-metrics",
        name: "EngagementMetrics",
        component: () => import("/src/views/admin/EngagementMetrics.vue"),
      },
      {
        path: "asset-download",
        name: "AssetDownload",
        component: () => import("/src/views/admin/AssetDownload.vue"),
      },
      {
        path: "collabestimates",
        name: "CollabEstimates",
        component: () => import("/src/views/admin/CollabEstimates.vue"),
      },
      {
        path: "video-summary",
        name: "AdminVideoSummary",
        component: () => import("/src/views/admin/AdminVideoSummary.vue"),
      },
      {
        path: "customers/test",
        name: "TestCustomer",
        component: () => import("/src/views/admin/TestCustomer.vue"),
      },
      {
        path: "customers/:id",
        name: "EditCustomer",
        component: () => import("/src/views/admin/EditCustomer/index.vue"),
      },
      {
        path: "customers",
        name: "AdminAllCustomers",
        component: () => import("/src/views/admin/AllCustomers.vue"),
      },
      {
        path: "paidcreatorsmap",
        name: "PaidCreatorsMap",
        component: () => import("/src/views/admin/PaidCreatorsMap.vue"),
      },
      {
        // New customer map
        path: "customerstatusmap",
        name: "TwoSidedMetricsMap",
        component: () => import("/src/views/admin/TwoSidedMetricsMap.vue"),
        beforeEnter: authGuard,
      },
      {
        // Old customer map
        path: "destinationsmap",
        name: "CommunitiesMap",
        component: CommunitiesMap,
        beforeEnter: authGuard,
      },
      {
        path: "collabresponses",
        name: "CollabApplications",
        component: () => import("/src/views/admin/CollabApplications.vue"),
      },
      {
        path: "leadgen",
        name: "LeadGen",
        component: () => import("/src/views/admin/LeadGen.vue"),
      },
      {
        path: "referrals",
        name: "Referrals",
        component: () => import("/src/views/admin/Referrals.vue"),
      },
      {
        path: "metrics",
        name: "BackendMetrics",
        component: () => import("/src/views/admin/BackendMetrics/index.vue"),
      },
      {
        path: "kpis",
        name: "Kpis",
        component: () => import("/src/views/admin/Kpis.vue"),
      },
    ],
  },
  {
    path: "/activity-feed",
    name: "ActivityFeed",
    component: () => import("/src/components/ActivityFeed/index.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/admin/collabs/:communityId",
    alias: ["/admin/:communityId/collabs", "/admin/community/:communityId/collabs"],
    name: "PaidCollabCommunityAdmin",
    component: PaidCollabCommunityAdmin,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/dashboard",
    alias: ["/spotlight", "/admin/community", "/start"],
    name: "CommunityAdminDashboard",
    component: CommunityAdminDashboard,
  },
  {
    path: "/spotlight/onboard",
    name: "SpotlightOnboard",
    component: SpotlightOrgSetup,
    beforeEnter: authGuard,
  },
  {
    // Photo walls (adventure walls was split out)
    path: "/admin/photowalls/:pageId",
    alias: "/admin/integration/:pageId",
    name: "WidgetDirections",
    component: WidgetDirections,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/adventurewalls/:pageId",
    name: "AdventureWidgets",
    component: AdventureWidgets,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/adventuresummary/:pageId",
    name: "AdventureSummary",
    component: AdventureSummary,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/:pageId/customintegration/:listId",
    name: "CustomIntegration",
    component: CustomIntegration,
    beforeEnter: authGuard,
  },
  {
    // Old Photos page
    path: "/admin/photos-old/:pageId",
    name: "CommunityPhotosOld",
    component: CommunityPhotosV2,
    beforeEnter: authGuard,
  },
  {
    // New Photos page
    path: "/admin/photos/:pageId",
    name: "CommunityPhotos",
    component: CommunityPhotosV3,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/:pageId/photowall-old/:photoWallId",
    name: "PhotoWallAdminOld",
    component: PhotoWallAdminOld,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/:pageId/photowall/:photoWallId",
    name: "PhotoWallAdmin",
    component: PhotoWallAdminV2,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/:pageId/youruploads/",
    name: "PhotoGalleryAdmin",
    component: PhotoGalleryAdmin,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/approvals/:pageId",
    name: "CommunityAdventureApproval",
    component: CommunityAdventureApproval,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/adventuremetrics/:pageId",
    name: "CommunityAdventureMetrics",
    component: CommunityAdventureMetrics,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/stats/:pageId",
    name: "CommunityStatsSummary",
    component: CommunityStatsSummary,
    beforeEnter: authGuard,
  },
  {
    // creatorId is optional
    path: "/creatordashboard/:creatorId?",
    // Collided with the CreatorProfilePublic route when we made the creatorId param optional
    alias: ["/creator/dashboard/:creatorId?", "/creator/:creatorId/availablecollabs"],
    name: "CreatorDashboard",
    component: CreatorDashboard,
    // Anonymous is allowed now so we can help creators onboard
  },
  {
    path: "/creator/:creatorId/collabs/:communityId/:collabInputId",
    name: "CollabOpportunity",
    component: CollabOpportunity,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/uploadcampaigns/:pageId",
    name: "UserUploadCampaigns",
    component: UserUploadCampaigns,
    beforeEnter: authGuard,
  },
  {
    path: "/upload/:pageId/:uploadIdentifier",
    name: "NonUserPhotoUpload",
    component: NonUserPhotoUpload,
  },
  {
    path: "/admin/:pageId/communityuploads",
    name: "UserUploadedGalleryAdmin",
    component: UserUploadedGalleryAdmin,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/sharestopv1/:pageId",
    alias: "/admin/:pageId/sharestopv1",
    name: "ShareStopSummary",
    component: ShareStopSummary,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/sharestop/:pageId",
    alias: "/admin/:pageId/sharestop",
    name: "ShareStopSummaryV2",
    component: ShareStopSummaryV2,
    beforeEnter: authGuard,
  },
  {
    path: "/admin/sharestop/:pageId/savedposts",
    alias: "/admin/:pageId/sharestop/savedposts",
    name: "SavedPosts",
    component: SavedPosts,
    beforeEnter: authGuard,
  },
  /* {
    path: '/quick-upload/:code',
    name: 'QuickImageUpload',
    component: QuickImageUpload,
  },*/

  // Redirects (we use these urls in some one-pager value prop docs that sales sends out)
  // Note: We could do these server-side also to avoid loading the vue app...
  {
    path: "/collabs",
    component: RedirectingElsewhere,
    beforeEnter() {
      location.href = "https://explore.shrpa.com/collabs/";
    },
  },
  {
    path: "/upgrades",
    component: RedirectingElsewhere,
    beforeEnter() {
      location.href = "https://explore.shrpa.com/upgrades/";
    },
  },
  {
    path: "/ugc",
    component: RedirectingElsewhere,
    beforeEnter() {
      location.href = "https://explore.shrpa.com/ugc/";
    },
  },

  { path: "/404", component: NotFound },
  { path: "/:pathMatch(.*)", redirect: "/404" },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  // https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior(to, from, savedPosition) {
    const allowSavedPosition = false;
    if (allowSavedPosition && savedPosition) {
      // console.info(`SavedPosition ${savedPosition.x}, ${savedPosition.y}`);
      return savedPosition;
    } else {
      // console.info("Scrolling to 0,0");
      return { top: 0, left: 0 };
    }
  },
});

// Fire a metric for each navigation
router.afterEach((to, from) => {
  // Note: Might not be setup for initial hit
  MetricSender.navigate(to.name, from.name ? from.name : "NA");
});

export default router;
