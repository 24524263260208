<template>
  <div class="collab-suggestions-page">
    <!-- Header -->
    <div class="header collab-suggestions-page__header">
      <h1 class="global-h1 header__title">
        Creator Visit Inspiration
        <span class="header__beta-badge">beta</span>
      </h1>
      <div class="header__subtitle">
        A world of possibilities to tell your destination's story!
        <div style="margin-top: 2px">Explore ideas inspired by past successful creator visits.</div>
      </div>
    </div>
    <!-- / Header -->

    <GenerateSuggestionsFormModal
      ref="domRefGenerateSuggestionsFormModal"
      :pageId="String(route.params.pageId)"
      @generate="
        collabSuggestionsArray => {
          collabSuggestionsList = collabSuggestionsList.concat(collabSuggestionsArray);
          domRefGenerateSuggestionsFormModal.closeModal();
        }
      "
    />

    <div class="collab-suggestions-page__divider"></div>

    <Loader v-if="isLoading" style="width: 100px; margin: 60px auto" />

    <TransitionScale v-else class="collab-suggestions-page__snippets-list" group :duration="350" easing="ease-in-out" tag="ul" no-move>
      <li class="collab-suggestions-page__snippet-wrap" v-for="suggestion in collabSuggestionsListSortedByDateAscending" :key="suggestion.id">
        <CollabSuggestionSnippet
          class="collab-suggestions-page__snippet"
          :collabSuggestion="suggestion"
          @getStarted="domRefGetStartedFormModal.openModal(suggestion.id, suggestion.theme)"
          @dislike="domRefDislikeFormModal.openModal(suggestion.id)"
        />
      </li>

      <li class="collab-suggestions-page__snippet-wrap">
        <!-- "Generate new" snippet overlay -->
        <div class="generate-new-snippet-overlay collab-suggestions-page__generate-new-snippet-overlay">
          <IconEmbedded class="generate-new-snippet-overlay__icon" name="ai-triple-solid" color="rgba(0, 0, 0, 0.3)" :size="110" />

          <div style="margin-bottom: 10px"><b>Provide Guidance and</b></div>
          <SrpButton
            class="generate-new-snippet-overlay__btn"
            @click="domRefGenerateSuggestionsFormModal?.openModal"
            :isDisabled="collabSuggestionsStore.isGenerating"
            :style="{ opacity: collabSuggestionsStore.isGenerating ? 0.8 : 1 }"
          >
            <template #icon v-if="collabSuggestionsStore.isGenerating">
              <LoadingIcon :size="22" color="rgba(0, 0, 0, 0.2)" />
            </template>
            <span v-if="collabSuggestionsStore.isGenerating">Generating Inspiration!</span>
            <span v-else>Generate more...</span>
          </SrpButton>
        </div>
        <!-- / "Generate new" snippet overlay -->

        <CollabSuggestionSnippet class="collab-suggestions-page__snippet" :collabSuggestion="collabSuggestionMock as CollabSuggestion" />
      </li>
    </TransitionScale>

    <GetStartedFormModal ref="domRefGetStartedFormModal" :pageId="String(route.params.pageId)" />

    <DislikeFormModal
      ref="domRefDislikeFormModal"
      :pageId="String(route.params.pageId)"
      @remove="collabSuggestionId => (collabSuggestionsList = collabSuggestionsList.filter(cs => cs.id !== collabSuggestionId))"
    />
  </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import collabSuggestionMock from "./collabSuggestionMock.json";
import { TransitionScale } from "@morev/vue-transitions";
import { useHead } from "@unhead/vue";

// Components
import CollabSuggestionSnippet from "./CollabSuggestionSnippet.vue";
import DislikeFormModal from "./DislikeFormModal.vue";
import GenerateSuggestionsFormModal from "./GenerateSuggestionsFormModal.vue";
import GetStartedFormModal from "./GetStartedFormModal.vue";
import Loader from "@components/Loader/Loader.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LoadingIcon from "@components/ui/LoadingIcon.vue";

// Types
import { CollabSuggestion } from "@contracts/collabSuggestions";

// Stores
import { useCollabSuggestionsStore } from "@stores/collabSuggestionsStore";
import { MetricSender } from "@/helpers/MetricSender";
const collabSuggestionsStore = useCollabSuggestionsStore();

// Router
const route = useRoute();

// Modal DOM refs =============================================================
const domRefGetStartedFormModal = ref<any>(null);
const domRefGenerateSuggestionsFormModal = ref<any>(null);
const domRefDislikeFormModal = ref<any>(null);

// Is loading =================================================================
const isLoading = ref<boolean>(false);

// Suggestions list ===========================================================
const collabSuggestionsList = ref<Array<CollabSuggestion | null>>(null);
const collabSuggestionsListSortedByDateAscending = computed<Array<CollabSuggestion>>(() => {
  if (collabSuggestionsList.value?.length) {
    const getTime = (date: string) => new Date(date).getTime();
    return [...collabSuggestionsList.value].sort((a, b) => getTime(a.createdDate) - getTime(b.createdDate));
  } else {
    return [];
  }
});

// Load the data ==============================================================
async function loadData(pageId: string): Promise<void> {
  isLoading.value = true;

  let uri = `${import.meta.env.VITE_API_URL}/collab-suggestions/${pageId}`;
  const { data } = await axios.get<Array<CollabSuggestion>>(uri);
  collabSuggestionsList.value = data;
  isLoading.value = false;

  // Default 4 new suggestions if they don't have any
  if (data.length === 0) {
    await domRefGenerateSuggestionsFormModal.value.generateSuggestions(4);
    // An emit should append the new suggestions to the list
  }
}

onMounted(async () => {
  useHead({ title: () => "Visit Inspiration - Shrpa", meta: [{ name: "description", content: "Visit Inspiration" }] });
  await loadData(String(route.params.pageId));

  MetricSender.sendMetric("Cust-ViewVisitInspiration", null);
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Header =====================================================================
.header {
  display: inline-block;
  position: relative;
  &__title {
    margin-bottom: 9px;
  }

  &__subtitle {
    color: rgba(91, 91, 91, 1);
    font: 14px/19px sans-serif;
  }

  &__beta-badge {
    height: 15px;
    padding: 0 4px 1px;
    box-sizing: border-box;
    border: 1px rgba(0, 0, 0, 0.3) solid;
    border-radius: 4px;
    display: block;
    position: absolute;
    inset: -4px -33px auto auto;
    color: rgba(0, 0, 0, 0.5);
    font: 12px/12px sans-serif;
    user-select: none;
  }
}

// "Generate new" snippet overlay =============================================
.generate-new-snippet-overlay {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);

  &__icon {
    width: 110px;
    height: 110px;
    margin-bottom: 17px;
  }

  &__btn {
  }
}

// Collab suggestions page ====================================================
.collab-suggestions-page {
  width: $desktop-wide-right-col-width;
  margin: 0 auto;

  &__header {
    margin-bottom: 30px;
  }

  &__divider {
    margin-bottom: 40px;
    display: flex; // prevents margins collapsing
    position: relative;

    &::before {
      content: "";
      width: 100%;
      height: 0;
      position: absolute;
      inset: auto auto auto 0;
      border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    }
  }

  &__snippets-list {
    gap: $desktop-wide-grid-gap-width;
    padding: 0;
    margin: 0 0 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    list-style: none;
  }

  &__snippet-wrap {
    width: calc(50% - $desktop-wide-grid-gap-width / 2);
    position: relative;
    z-index: 0;
  }

  &__generate-new-snippet-overlay {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    position: absolute;
    inset: 1px auto auto 1px;
    z-index: 1;
  }

  &__snippet {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .collab-suggestions-page {
    width: $desktop-right-col-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .collab-suggestions-page {
    width: $laptop-right-col-width;

    &__snippets-list {
      gap: $laptop-grid-gap-width;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .collab-suggestions-page {
    width: $tablet-large-content-width;

    &__snippets-list {
      gap: $tablet-large-grid-gap-width;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .collab-suggestions-page {
    width: $tablet-content-width;

    &__snippets-list {
      gap: $tablet-grid-gap-width;
    }

    &__snippet-wrap {
      width: 100%;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .header {
    &__beta-badge {
      top: -13px;
      right: 0;
    }
  }

  .collab-suggestions-page {
    width: $mobile-content-width;

    &__snippets-list {
      gap: $mobile-grid-gap-width;
    }

    &__snippet-wrap {
      width: 100%;
    }
  }
}
</style>
